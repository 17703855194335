<template>
  <div class="contents-section">
    <div class="title-section">
      <h2 class="d-flex align-start">
        상품관리
        <v-tooltip right color="black">
          <template #activator="{ on, attrs }">
            <v-btn
              icon class="mt-1 ml-1"
              v-bind="attrs" v-on="on"
              @click="getSummary()"
              :loading="loading"
              color="primary"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>새로고침</span>
        </v-tooltip>
      </h2>
      <v-btn
        depressed
        color="primary"
        @click="registDlgOpen()"
      >
        상품등록
      </v-btn>
    </div>
    <div class="main-section">
      <search-filter
        :radioFilters="radioFilters"
        :search="search"
        type="server"
        searchPlaceHolder="상품명/등록인"
        @updateSearch="updateSearch"
        @updateRadio="updateRadio"
      />
      <v-row
        justify="space-between"
        align="center"
        class="mr-0 ml-0 mt-0 mb-0"
      >
        <v-col
          class="d-flex align-center pl-0"
        >
          <v-tooltip top color="black">
            <template #activator="{ on: tooltipOn }">
              <v-chip
                class="mt-4 mr-2 pr-1 pl-1 flex-shrink-0"
                label
                outlined
                elevation
                link
                color="success"
                v-on="tooltipOn"
                @click="excelDownloadFunc()"
                :disabled="excelDownloadList === null"
              >
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-chip>
            </template>
            <span>상품목록 다운로드</span>
          </v-tooltip>
          <v-radio-group
            @change="options.itemsPerPage = $event"
            dense
            row
            :value="options.itemsPerPage"
            hide-details
          >
            <v-radio
              small
              label="50개씩 보기"
              :value="50"
            ></v-radio>
            <v-radio
              small
              label="100개씩 보기"
              :value="100"
            ></v-radio>
            <v-radio
              small
              label="500개씩 보기"
              :value="500"
            ></v-radio>
            <v-radio
              small
              label="1000개씩 보기"
              :value="1000"
            ></v-radio>
            <v-radio
              small
              label="3000개씩 보기"
              :value="3000"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="lists"
        item-key="no"
        :options="options"
        :loading="loading"
        class="elevation-0"
        dense
        checkbox-color="primary"
        :server-items-length="totalItems"
        hide-default-footer
        @update:sort-by="updateOptions('sortBy', $event)"
        @update:sort-desc="updateOptions('sortDesc', $event)"
      >
        <template #[`item.type`]="{ item }">
          <div class="d-flex align-center" v-if="item.type === 0">
            <v-chip
              class="pl-2 pr-2"
              small label color="info"
            >
              리딩
            </v-chip>
            <v-tooltip top color="black" v-if="item.type === 0">
              <template #activator="{ on }">
                <v-btn icon small color="primary" class="mb-1"
                  v-on="on"
                  @click="productRoomsDlgOpen(item)"
                >
                  <v-icon size="18px">mdi-chat-question</v-icon>
                </v-btn>
              </template>
              <span>
                리딩방 상세보기 및 수정
              </span>
            </v-tooltip>
          </div>
          <v-chip
            v-else-if="item.type === 1"
            class="pl-2 pr-2"
            small label color="warning"
          >
            코인
          </v-chip>
        </template>
        <template #[`item.name`]="{ item }">
          <v-menu offset-y :close-on-content-click="false">
            <template #activator="{ on, attrs }">
              {{ item.name }}
              <v-btn small icon v-bind="attrs" v-on="on" @click="productName = item.name">
                <v-icon small color="primary">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <div class="text-input">
              <v-text-field
                :autofocus="true"
                solo
                :value="productName"
                dense
                flat
                hide-details
                @input="productName = $event"
                @keydown="if($event.key === 'Enter') {
                  updateName(item.no, productName);
                }"
              ></v-text-field>
              <v-btn class="mt-1" color="primary"
                depressed dense small
                @click="updateName(item.no, productName)"
              >수정</v-btn>
            </div>
          </v-menu>
        </template>
        <template #[`item.price`]="{ item }">
          {{ comm.priceToString(item.price) }}
        </template>
        <template #[`item.term`]="{ item }">
          <span v-if="item.type === 0">
            {{ item.term }} 개월
          </span>
          <span v-else class="gray--text">-</span>
        </template>
        <template #[`item.freeTerm`]="{ item }">
          <span v-if="item.type === 0">
            {{ item.freeTerm }} 개월
          </span>
          <span v-else class="gray--text">-</span>
        </template>
        <template #[`item.disabled`]="{ item }">
          <v-chip small link :color="item.disabled === true ? 'error' : 'success'"
            class="pr-2"
            @click="diabledFunc(item.name, item.no, item.disabled)"
          >
            {{ item.disabled === true ? '비활성' : '활성' }}
            <v-icon small class="ml-1">mdi-swap-horizontal</v-icon>
          </v-chip>
        </template>
        <template #[`item.created`]="{ item }">
          {{ time.makeLocalTime(item.created, 'min') }}
        </template>
        <template #[`item.creator`]="{ item }">
          <v-chip
            class="pl-1 pr-1"
            small label outlined
          >{{ comm.getGroupFullPath(groups, item.creatorGroupNo, '그룹없음') }}</v-chip>
          {{ item.creatorName }}
        </template>
      </v-data-table>
      <div class="text-center pt-4 d-flex justify-center align-center">
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = 1"
          :disabled="options.page === 1"
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>
        <v-pagination
          v-if="options.itemsPerPage > 0"
          small
          color="primary"
          v-model="options.page"
          :length="Math.ceil(totalItems / options.itemsPerPage)"
          totalVisible="15"
          depressed
        ></v-pagination>
        <v-btn
          icon
          v-if="Math.ceil(totalItems / options.itemsPerPage) > 1"
          @click="options.page = Math.ceil(totalItems / options.itemsPerPage)"
          :disabled="options.page === Math.ceil(totalItems / options.itemsPerPage)"
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="roomDlg.show"
      scrollable
      max-width="500"
      @click:outside="roomDlgInit"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center pt-6"
        >
          <span class="text-h5">리딩방수정</span>
          <v-chip small class="ml-2" v-if="roomDlg.product !== null">
            {{ roomDlg.product.name }}
          </v-chip>
        </v-card-title>
        <v-card-text class="pb-0 content-wrap">
          <div class="content-box mt-0" style="border-top: 0px">
            <div class="section-wrap">
              <div class="section pl-0 pr-0 pt-0 pb-0" v-if="roomDlg.rooms.length > 0">
                <div class="d-flex flex-wrap room-wrap pl-2" style="max-width: 100%">
                  <v-checkbox
                    v-for="room in roomDlg.rooms"
                    :key="room.no"
                    v-model="room.checked"
                    :label="room.name"
                    :checked="true"
                    color="primary"
                    class="mt-2 pb-4 mr-4"
                    hide-details
                  />
                </div>
              </div>
              <div v-else class="pa-4 text-center pb-10">
                등록된 리딩방이 없습니다.
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-0" v-if="roomDlg.rooms.length > 0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="updateRooms"
          >
            수정
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialog.show"
      scrollable
      max-width="500"
      @click:outside="init"
    >
      <v-card>
        <v-card-title
          class="d-flex align-center justify-space-between pt-6"
        >
          <span class="text-h5">상품등록</span>
          <v-radio-group
            @change="dialog.type = $event"
            dense
            row
            :value="dialog.type"
          >
            <v-radio
              label="리딩"
              :value="0"
              class="mr-4"
            ></v-radio>
            <v-radio
              label="코인"
              :value="1"
              class="mr-4"
            ></v-radio>
          </v-radio-group>
        </v-card-title>
        <v-card-text class="pb-0 content-wrap">
          <div class="content-box">
            <div class="section-wrap">
              <div class="section">
                <p class="tit">상품명</p>
                <div class="text-input-wrap ml-2">
                  <v-text-field
                    v-model="dialog.name"
                    outlined
                    dense
                    flat
                    label="상품명"
                    :rules="[nameRule]"
                    autofocus
                  />
                </div>
              </div>
              <div class="section">
                <p class="tit">판매가</p>
                <div class="text-input-wrap ml-2">
                  <price-text
                    v-model="dialog.price"
                    outlined
                    dense
                    flat
                    label="가격(원)"
                    :rules="[priceRule]"
                  />
                </div>
              </div>
              <div class="section" v-if="dialog.type === 0 && rooms.length > 0">
                <p class="tit flex-shrink-0" style="margin-right: 80px">리딩방</p>
                <div class="d-flex flex-wrap room-wrap pl-2">
                  <v-checkbox
                    v-for="room in rooms"
                    :key="room.no"
                    :label="room.name"
                    v-model="room.checked"
                    color="primary"
                    class="mt-2 pb-4 mr-4"
                    hide-details
                  />
                </div>
              </div>
              <div class="section" v-if="dialog.type === 0">
                <p class="tit">이용기간</p>
                <div class="text-input-wrap ml-2">
                  <v-text-field
                    v-model="dialog.term"
                    outlined
                    dense
                    flat
                    label="기간(개월)"
                    :rules="[termRule]"
                  />
                </div>
              </div>
              <div class="section" v-if="dialog.type === 0">
                <p class="tit">
                  무료이용기간
                  <v-tooltip right color="black">
                    <template #activator="{ on, attrs }">
                      <v-icon
                        class="question"
                        small
                        color="warning"
                        v-on="on"
                        v-bind="attrs"
                      >mdi-help-circle-outline</v-icon>
                    </template>
                    <span>
                      무료이용기간은 환불시 일할계산에 포함되지 않습니다.
                    </span>
                  </v-tooltip>
                </p>
                <div class="text-input-wrap ml-2">
                  <v-text-field
                    v-model="dialog.freeTerm"
                    outlined
                    dense
                    flat
                    label="기간(개월)"
                    :rules="[freeTermRule]"
                  />
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="pb-6 pr-6 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            :disabled="!/^[a-zA-Z가-힣ㄱ-ㅎ0-9]{1,15}$/g.test(dialog.name)
              || dialog.price >= 1000000000"
            @click="registProduct"
          >
            생성
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import SearchFilter from '@/components/layout/SearchFilter.vue';
import PriceText from '@/components/input/PriceText.vue';
import time from '@/util/time';
import comm from '@/util/comm';

export default {
  name: 'Product',
  components: {
    SearchFilter,
    PriceText,
  },
  data: () => ({
    comm,
    time,
    productName: '',
    dialog: {
      show: false,
      type: 0,
      name: '',
      price: 0,
      term: 0,
      freeTerm: 0,
    },
    nameRule: (value) => {
      const reg = /^[a-zA-Z가-힣ㄱ-ㅎ0-9]{1,15}$/g;
      return reg.test(value) || '1~15자 영문, 숫자만 가능합니다.';
    },
    priceRule: (value) => {
      const val = Number(value.replaceAll(',', ''));
      if (val < 1 || val > 1000000000 || value === 'NaN') {
        return '1 이상 10억 미만의 숫자로 입력해주세요.';
      }
      return true;
    },
    termRule: (value) => {
      const val = Number(value);
      if (val < 1 || val > 120 || Number.isNaN(val)) {
        return '1 이상 120 미만의 숫자로 입력해주세요.';
      }
      return true;
    },
    freeTermRule: (value) => {
      const val = Number(value);
      if (val < 0 || val > 120 || Number.isNaN(val)) {
        return '0 이상 120 미만의 숫자로 입력해주세요.';
      }
      return true;
    },
    groups: [],
    loading: true,
    lists: [],
    search: '',
    totalItems: 0,
    radioFilters: {
      type: {
        key: 'type',
        name: '종류',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '리딩', value: 0 },
          { name: '코인', value: 1 },
        ],
      },
      status: {
        key: 'status',
        name: '상태',
        value: '전체',
        data: [
          { name: '전체', value: '전체' },
          { name: '활성', value: false },
          { name: '비활성', value: true },
        ],
      },
    },
    options: {
      page: 1,
      itemsPerPage: 50,
      sortBy: ['no'],
      sortDesc: [true],
      filters: [
        {
          condition: 'inc',
          column: 'type',
          value: '',
        },
        {
          where: 'and',
          condition: 'inc',
          column: 'disabled',
          value: '',
        },
        {
          where: 'and',
          filters: [
            {
              condition: 'inc',
              column: 'name',
              value: '',
            },
            {
              where: 'or',
              condition: 'inc',
              column: 'creatorName',
              value: '',
            },
          ],
        },
      ],
    },
    excelDownloadList: [],
    rooms: [],
    roomDlg: {
      show: false,
      product: null,
      rooms: [],
    },
  }),
  computed: {
    ...mapGetters({
      userInfo: 'auth/userInfo',
      route: 'router/route',
    }),
    headers() {
      return [
        {
          text: '종류',
          value: 'type',
          align: 'left',
          sortable: true,
          width: 100,
        },
        {
          text: '상품명',
          value: 'name',
          align: 'left',
          sortable: false,
        },

        {
          text: '가격',
          value: 'price',
          align: 'left',
          sortable: false,
        },
        {
          text: '이용기간',
          value: 'term',
          align: 'left',
          sortable: false,
        },
        {
          text: '무료이용기간',
          value: 'freeTerm',
          align: 'left',
          sortable: false,
        },
        {
          text: '등록인',
          value: 'creator',
          align: 'left',
          sortable: false,
        },
        {
          text: '활성여부',
          value: 'disabled',
          align: 'center',
          width: 100,
          sortable: false,
        },
      ];
    },
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      confirm: 'dialog/confirm',
    }),
    ...mapActions({
      alert: 'dialog/alert',
    }),
    /* 검색 업데이트 */
    updateSearch(value) {
      this.search = value;
    },
    /* 라디오필터 업데이트 */
    updateRadio(value, key) {
      this.radioFilters[key].value = value;
    },
    getList() {
      this.$socket.emit('products.list.get', this.options, (resp) => {
        if (resp.result === 'success') {
          this.lists = resp.items;
          this.loading = false;
          if (this.options.page > Math.ceil(this.totalItems / this.options.itemsPerPage)) {
            this.options.page = 1;
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.loading = false;
        }
      });
    },
    getSummary() {
      this.loading = true;
      this.$socket.emit('products.summary.get', {
        filters: this.options.filters,
      }, (resp) => {
        if (resp.result === 'success') {
          this.totalItems = resp.item.count;
          this.getList();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    updateOptions(type, value) {
      let valArr = [];
      valArr = [value];
      this.options[type] = valArr;
    },
    getGroupList() {
      this.$socket.emit('groups.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [false],
        filters: [],
      }, (resp) => {
        if (resp.result === 'success') {
          this.groups = resp.items.groups;
          this.getSummary();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    diabledFunc(name, no, disabled) {
      if (disabled === true) {
        this.$socket.emit('products.update', {
          no,
          disabled: false,
        }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '상품이 복구되었습니다.']);
            this.getSummary();
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      } else if (disabled === false) {
        const func = () => {
          this.confirm({ show: false });
          this.$socket.emit('products.update', {
            no,
            disabled: true,
          }, (resp) => {
            if (resp.result === 'success') {
              this.alert(['success', '상품을 비활성화 하였습니다.']);
              this.getSummary();
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
              console.error(resp);
            }
          });
        };
        this.confirm({
          show: true,
          func,
          msg: `정말 '${name}' 상품을 비활성화 할까요?`,
          btnText: '비활성화',
          color: 'error',
        });
      }
    },
    updateName(no, value) {
      const ruleTest = /^[a-zA-Z가-힣ㄱ-ㅎ0-9]{1,15}$/g.test(value);
      if (ruleTest === false) {
        this.alert(['error', '상품명은 1~15자 영문, 숫자만 가능합니다.']);
      } else {
        this.$socket.emit('products.update', {
          no,
          name: value,
        }, (resp) => {
          if (resp.result === 'success') {
            this.alert(['success', '상품명이 변경되었습니다.']);
            this.getSummary();
          } else {
            this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
            console.error(resp);
          }
        });
      }
    },
    updateRooms() {
      this.progress(true);
      this.$socket.emit('products.rooms.update', {
        no: this.roomDlg.product.no,
        rooms: this.roomDlg.rooms.reduce((acc, room) => {
          if (room.checked === true) {
            acc.push(room.no);
          }
          return acc;
        }, []).sort(),
      }, (resp) => {
        if (resp.result === 'success') {
          this.roomDlgInit();
          this.alert(['success', '리딩방이 수정되었습니다.']);
          this.getSummary();
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    init() {
      this.dialog.show = false;
      this.dialog.name = '';
      this.dialog.type = 0;
      this.dialog.price = 0;
      this.dialog.term = 0;
      this.dialog.freeTerm = 0;
      this.rooms = [];
    },
    roomDlgInit() {
      this.roomDlg.product = null;
      this.roomDlg.rooms = [];
      this.roomDlg.show = false;
    },
    registProduct() {
      const obj = {
        type: Number(this.dialog.type),
        name: String(this.dialog.name),
        price: Number(this.dialog.price),
        term: Number(this.dialog.term),
        freeTerm: Number(this.dialog.freeTerm),
        rooms: this.rooms.reduce((acc, r) => {
          if (r.checked === true) {
            acc.push(r.no);
          }
          return acc;
        }, []),
      };
      if (Number(this.dialog.type) === 1) {
        obj.term = 0;
        obj.freeTerm = 0;
      }
      this.$socket.emit('products.add', obj, (resp) => {
        if (resp.result === 'success') {
          this.alert(['success', '상품이 등록되었습니다.']);
          this.getSummary();
          this.init();
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
        }
      });
    },
    excelDownloadFunc() {
      this.progress(true);
      this.excelDownloadList = null;
      this.$socket.emit('products.list.get', {
        ...this.options,
        itemsPerPage: 0,
      }, (resp) => {
        if (resp.result === 'success') {
          if (resp.items.length <= 0) {
            this.alert(['error', '다운로드할 목록이 없습니다.']);
            this.excelDownloadList = [];
            this.progress(false);
          } else {
            const list = resp.items.reduce((arr, r) => {
              const typeArr = ['리딩', '코인'];
              const row = {};
              row['상품번호'] = r.no;
              row['종류'] = typeArr[r.type];
              row['상품명'] = r.name;
              row['가격'] = r.price;
              row['이용기간(개월)'] = r.type === 0 ? r.term : '-';
              row['무료이용기간(개월)'] = r.type === 0 ? r.freeTerm : '-';
              row['등록인'] = `${comm.getGroupFullPath(this.groups, r.creatorGroupNo, '그룹없음 ')}${r.creatorName}`;
              row['등록일시'] = time.makeLocalTime(r.created, 'min');
              row['상태'] = r.disabled === true ? '비활성' : '활성';
              arr.push(row);
              return arr;
            }, []);
            this.excelDownloadList = list;
            comm.makeExcelFile(this.excelDownloadList, `상품목록[${time.moment().format('YYYY-MM-DD HH_mm_ss')}].xlsx`);
            this.progress(false);
          }
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
          this.excelDownloadList = [];
        }
      });
    },
    registDlgOpen() {
      this.progress(true);
      this.$socket.emit('rooms.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [true],
        filters: [{
          condition: 'eq',
          column: 'disabled',
          value: 0,
        }],
      }, (resp) => {
        if (resp.result === 'success') {
          this.init();
          this.dialog.show = true;
          this.rooms = resp.items;
          this.progress(false);
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
    productRoomsDlgOpen(product) {
      this.progress(true);
      this.roomDlg.product = product;
      this.$socket.emit('rooms.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: ['no'],
        sortDesc: [true],
        filters: [{
          condition: 'eq',
          column: 'disabled',
          value: 0,
        }],
      }, (resp) => {
        if (resp.result === 'success') {
          this.rooms = resp.items;
          this.$socket.emit('rooms.list.get', {
            page: 1,
            itemsPerPage: 0,
            sortBy: ['no'],
            sortDesc: [true],
            filters: [{
              condition: 'eq',
              column: 'disabled',
              value: 0,
            }, {
              where: 'and',
              condition: 'eq',
              column: 'productNo',
              value: this.roomDlg.product.no,
            }],
          }, (response) => {
            if (response.result === 'success') {
              this.roomDlg.rooms = this.rooms.map((room) => ({
                ...room,
                checked: response.items.filter((r) => r.no === room.no).length > 0,
              }));
              this.roomDlg.show = true;
              this.progress(false);
            } else {
              this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${response.code})`]);
              console.error(response);
              this.progress(false);
            }
          });
        } else {
          this.alert(['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`]);
          console.error(resp);
          this.progress(false);
        }
      });
    },
  },
  watch: {
    userInfo: {
      immediate: true,
      handler(val) {
        if (val !== null) {
          this.getGroupList();
        }
      },
    },
    options: {
      deep: true,
      handler() {
        if (this.userInfo != null && this.totalItems >= 0) {
          this.getSummary();
        }
      },
    },
    radioFilters: {
      deep: true,
      handler(val) {
        if (this.userInfo != null) {
          const status = val.status.value === '전체' ? '' : val.status.value;
          const type = val.type.value === '전체' ? '' : val.type.value;
          this.options.filters[0].value = type;
          this.options.filters[1].value = status;
        }
      },
    },
    search: {
      handler(val) {
        if (this.userInfo != null) {
          this.options.filters[2].filters[0].value = val;
          this.options.filters[2].filters[1].value = val;
        }
      },
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.text-input {
  background-color: #fff;
  padding: 5px;
  padding-right: 8px;
  padding-top:2px;
  width: 180px;
  display: flex;
  align-items: center;
}
.content-wrap {
  .text-input-wrap {
    width: 280px;
    margin-bottom: -10px;
    margin-top: 10px;
  }
  .content-box {
    border-top: 1px solid #ccc;
    margin-top: 10px;
    .pay-list {
      .pay-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        padding-top:0px;
        .pay-info {
          display: flex;
          align-items: center;
          .pay-text {
            color: #262626;
          }
        }
      }
    }
    .section {
      position: relative;
      padding: 10px;
      display: flex;
      align-items: center;
      color: #333;
      justify-content: space-between;
      & + .section, & + .section-wrap {
        border-top: 1px solid #ccc;
      }
      .pay-form {
        padding: 10px;
        min-width: 500px;
        max-width: 500px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top:-30px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
      .search-picker {
        width: 250px;
        max-width: 250px;
        position: absolute;
        right: 30px;
        z-index: 1;
        top:8px;
        box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
        .v-list {
          max-height: 100px;
          overflow: auto;
        }
        &.search-picker-wide {
          min-width: 300px;
          max-width: 800px;
        }
      }
      .room-wrap {
        max-height: 300px;
        overflow-y: auto;
        max-width: 320px;
      }
    }
    .section-wrap {
      & + .section, & + .section-wrap {
        border-top: 1px solid #ccc;
      }
      .section + .section {
        border-top: 1px dashed #ccc;
      }
    }
    .tit {
      font-size: .9rem;
      font-weight: 500;
      color: #262626;
      margin: 0;
      margin-right: 10px;
    }
  }
}
.gray--text {
  color: #ccc;
}
</style>
